import { FCP_Exception, FCP_Feature } from "@/@types/park";
import Spacer from "@/components/Spacer";
import AlertSimple from "@/components/alert/AlertSimple";
import FieldRadiosInline from "@/components/form/fields/FieldRadiosInline";
import FormError from "@/components/form/layout/FormError";
import FormHeading from "@/components/form/layout/FormHeading";
import { FormField, FormFields } from "@/components/form/layout/FormStacked";
import ParkAddShell from "@/components/layout/park/add/Shell";
import { Button } from "@/components/ui/button";
import keys from "@/config/keys";
import useParkAdd from "@/hooks/context/useParkAdd";
import { useLazyGetExceptions } from "@/hooks/query/useLazyGetExceptions";
import { CPException } from "@/models/exceptions/CPException";
import { useSubmitParkMutation } from "@/services/park";
import { formatDate, getDaysArray } from "@/utils/date";
import { formatAddress } from "@/utils/formatter";
import { getLanguage } from "@/utils/language";
import { getCurrentPark, removeCurrentPark } from "@/utils/storage";
import { getHrTimebloc } from "@/utils/timebloc";
import * as tracker from "@/utils/tracker";
import { ArrowRightIcon } from "@heroicons/react/24/outline";
import { IconPencil } from "@tabler/icons-react";
import { isSameDay } from "date-fns";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useEffectOnce } from "react-use";

export default function ParkAddReview() {
  const { t } = useTranslation(["park", "validation", "dates", "common"]);

  const navigate = useNavigate();
  const [error, setError] = useState<string | undefined>();
  const [loading, setLoading] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [exceptions, setExceptions] = useState<FCP_Exception[]>([]);

  const [spotOwner, setSpotOwner] = useState<string>("");
  const [liveOnSite, setLiveOnSite] = useState<string>("");
  const [canInstallSign, setCanInstallSign] = useState<string>("");
  const [clearedOfSnow, setClearedOfSnow] = useState<string>("");

  const { back, exit, goTo, updatePark, onboardingComplete } = useParkAdd();
  const park = getCurrentPark();
  const getExceptions = useLazyGetExceptions();
  const { mutateAsync: submitAsync } = useSubmitParkMutation();

  const photosAreMissing = park?.photos?.length === 0;
  const spotOwnerIsMissing = spotOwner === "";
  const liveOnSiteIsMissing = liveOnSite === "";
  const canInstallSignIsMissing = canInstallSign === "";
  const clearedOfSnowIsMissing = clearedOfSnow === "";

  async function fetchExceptions() {
    if (!park?.objectId) return;
    const data = await getExceptions({ parkId: park.objectId });
    setExceptions(data.map((d) => ({ ...d.exception, deleting: false })));
    setLoading(false);
  }

  useEffectOnce(() => {
    fetchExceptions();
  });

  async function submit() {
    if (!park?.objectId) return;
    try {
      setIsSubmitting(true);

      await updatePark({
        metadata: {
          ...(park?.metadata ? park.metadata : {}),
          spotOwner: {
            value: spotOwner === "true",
            type: "boolean",
            label: "Propriétaire de l'espace",
          },
          liveOnSite: {
            value: liveOnSite === "true",
            type: "boolean",
            label: "Habite sur les lieux",
          },
          canInstallSign: {
            value: canInstallSign === "true",
            type: "boolean",
            label: "Permission de poser affichette",
          },
          clearedOfSnow: {
            value: clearedOfSnow === "true",
            type: "boolean",
            label: "Espace déneigé",
          },
        },
      });

      const updatedUxMetadata = {
        ...(park?.uxMetadata ? park.uxMetadata : {}),
      };

      if (updatedUxMetadata.resumeToStep) {
        delete updatedUxMetadata.resumeToStep;
        await updatePark({ uxMetadata: updatedUxMetadata });
      }

      await submitAsync({ parkId: park.objectId });

      tracker.track.parkAdd.submitted(park.objectId);

      removeCurrentPark();

      if (localStorage.getItem(keys.redirect_to_bifrost_after_add_park)) {
        localStorage.removeItem(keys.redirect_to_bifrost_after_add_park);
        const redirectTo = localStorage.getItem(keys.redirect_after_action_url);
        if (redirectTo) window.location.href = redirectTo;
      } else {
        navigate(onboardingComplete ? "/parks" : "/parks/add/submitted", {
          replace: true,
        });
      }
    } catch (error) {
      if (error instanceof CPException) {
        setError(error.message);
      } else {
        setError(t("validation:genericError"));
      }

      setIsSubmitting(false);
    }
  }

  function onBack() {
    back();
  }

  async function onNext() {
    await submit();
  }

  function onSaveAndExit() {
    exit();
  }

  const EditButton = ({ onClick }: { onClick: () => void }) => (
    <Button
      onClick={onClick}
      className="ml-3 translate-y-[-1px] pb-[5px] pl-[5px] pr-[5px] pt-[5px]"
      size="sm"
      variant="subtle"
    >
      <IconPencil size={15} />
    </Button>
  );

  return (
    <ParkAddShell
      loading={loading || isSubmitting}
      actions={{
        saveAndExit: {
          visible: true,
          disabled: isSubmitting,
          onClick: onSaveAndExit,
        },
        next: {
          visible: true,
          disabled:
            spotOwnerIsMissing ||
            liveOnSiteIsMissing ||
            canInstallSignIsMissing ||
            clearedOfSnowIsMissing ||
            isSubmitting,
          onClick: onNext,
          submit: true,
        },
        back: {
          visible: true,
          disabled: isSubmitting,
          onClick: onBack,
        },
      }}
    >
      {error && <FormError title={error} />}

      <FormFields
        fields={[
          <FormField key="header">
            <FormHeading
              title={t("park:submitPark")}
              subtitle={t("park:submitParkSubtitle")}
            />
          </FormField>,
        ]}
      />

      <div className="rounded-lg border border-silver-800 bg-white shadow">
        <div className="px-4 py-5 sm:p-6">
          <div>
            <strong>
              {park?.address && formatAddress(park.address, "short")}
            </strong>
          </div>
        </div>

        <ul>
          <li className="border-t p-4 text-sm">
            <strong className="flex items-center justify-between">
              {t("park:numberOfSpaces")} <EditButton onClick={() => goTo(5)} />
            </strong>
            <div className="mt-1">
              <span>{park?.spaceQuantity}</span>
            </div>
          </li>
          <li className="border-t p-4 text-sm">
            <strong className="flex items-center justify-between">
              {t("park:vehicleMaxSize")} <EditButton onClick={() => goTo(6)} />
            </strong>
            <div className="mt-1">
              {park?.maxVehicleSize && t(`park:${park.maxVehicleSize}`)}
            </div>
          </li>
          <li className="border-t p-4 text-sm">
            <strong className="flex items-center justify-between">
              {t("park:features")} <EditButton onClick={() => goTo(7)} />
            </strong>
            {park?.features?.length === 0 ? (
              <p className="mt-1">{t("park:noFeatures")}</p>
            ) : (
              <div className="mt-1">
                {park?.features
                  ?.map((feature: FCP_Feature) => t(`park:${feature}`))
                  .join(", ")}
              </div>
            )}
          </li>
          <li className="border-t p-4 text-sm">
            <strong className="flex items-center justify-between">
              {t("park:howToGetThere")}
              <EditButton onClick={() => goTo(5)} />
            </strong>
            <p className="mt-1">
              {park?.localizedDirections &&
                park?.localizedDirections[getLanguage()]}
            </p>
          </li>
          <li className="border-t p-3 text-sm">
            <strong className="flex items-center justify-between">
              {t("park:photos")} <EditButton onClick={() => goTo(8)} />
            </strong>
            <ul className="mt-1 flex">
              {photosAreMissing ? (
                <p className="mt-1">{t("park:noPhotos")}</p>
              ) : (
                park?.photos?.map((photo) => (
                  <li className="mt-1 flex" key={photo.id}>
                    <div
                      className="relative mr-2 h-[60px] w-[60px] rounded-md border border-silver-600 bg-cover shadow-sm"
                      style={{
                        backgroundImage: ` url(${photo.files.thumbnail.path})`,
                      }}
                    />
                  </li>
                ))
              )}
            </ul>
          </li>
          <li className="border-t p-3 text-sm">
            <strong className="flex items-center justify-between">
              {t("park:schedule")} <EditButton onClick={() => goTo(9)} />
            </strong>

            {park?.allTimeAvail ? (
              <p className="mt-1">{t("park:scheduleAllTime")}</p>
            ) : (
              <ul className="mt-1">
                {getDaysArray().map((day) =>
                  park?.dailyAvail?.[day] ? (
                    <li className="mt-2 first:mt-0" key={day}>
                      <div className="mb-1 font-semibold">
                        {t(`dates:${day}`)}:
                      </div>
                      <div>
                        {park?.dailyAvail?.[day]?.map((avail, index) => (
                          <p key={day + index}>
                            {getHrTimebloc(avail.start.time)} -{" "}
                            {getHrTimebloc(avail.end.time)}
                          </p>
                        ))}
                      </div>
                    </li>
                  ) : null
                )}
              </ul>
            )}
          </li>
          <li className="border-t p-4 text-sm">
            <strong className="flex items-center justify-between">
              {t("park:blockedDates")} <EditButton onClick={() => goTo(1)} />
            </strong>
            {exceptions.length === 0 ? (
              <p className="mt-1">{t("park:noExceptions")}</p>
            ) : (
              <ul className="mt-2">
                {exceptions.map((exception) => {
                  const start = new Date(exception.startDate.iso);
                  const end = new Date(exception.endDate.iso);

                  let formattedDate: React.ReactNode = (
                    <div className="flex font-normal">
                      <div>{formatDate(start)}</div>
                      <div className="ml-1">00:00</div>
                      <ArrowRightIcon className="mx-2 w-[15px]" />
                      <div>{formatDate(end)}</div>
                      <div className="mx-1">23:59</div>
                    </div>
                  );

                  if (isSameDay(start, end)) {
                    formattedDate = (
                      <div className="flex font-normal">
                        <div>{formatDate(start)}</div>
                        <div className="ml-1">00:00</div>
                        <ArrowRightIcon className="mx-2 w-[15px]" />
                        <div className="mr-1">23:59</div>
                      </div>
                    );
                  }

                  return (
                    <li className="mt-1" key={exception.objectId}>
                      {formattedDate}
                    </li>
                  );
                })}
              </ul>
            )}
          </li>
        </ul>
      </div>

      <Spacer />

      <div className="rounded-lg border border-silver-800 bg-white shadow">
        <div className="px-4 py-5 sm:p-6">
          <strong className="mb-3 block text-sm">{t("park:spotOwner")}</strong>

          <FieldRadiosInline
            selectedOptionValue={spotOwner}
            onSelect={({ value }) => setSpotOwner(value)}
            options={[
              {
                name: t("common:yes"),
                value: "true",
              },
              {
                name: t("common:no"),
                value: "false",
              },
            ]}
          />
        </div>
      </div>

      <div className="mt-5 rounded-lg border border-silver-800 bg-white shadow">
        <div className="px-4 py-5 sm:p-6">
          <strong className="mb-3 block text-sm">{t("park:liveOnSite")}</strong>

          <FieldRadiosInline
            selectedOptionValue={liveOnSite}
            onSelect={({ value }) => setLiveOnSite(value)}
            options={[
              {
                name: t("common:yes"),
                value: "true",
              },
              {
                name: t("common:no"),
                value: "false",
              },
            ]}
          />
        </div>
      </div>

      <div className="mt-5 rounded-lg border border-silver-800 bg-white shadow">
        <div className="px-4 py-5 sm:p-6">
          <strong className="mb-3 block text-sm">
            {t("park:canInstallSign")}
          </strong>

          <FieldRadiosInline
            selectedOptionValue={canInstallSign}
            onSelect={({ value }) => setCanInstallSign(value)}
            options={[
              {
                name: t("common:yes"),
                value: "true",
              },
              {
                name: t("common:no"),
                value: "false",
              },
            ]}
          />
        </div>
      </div>

      <div className="mt-5 rounded-lg border border-silver-800 bg-white shadow">
        <div className="px-4 py-5 sm:p-6">
          <strong className="mb-3 block text-sm">
            {t("park:clearedOfSnow")}
          </strong>

          <FieldRadiosInline
            selectedOptionValue={clearedOfSnow}
            onSelect={({ value }) => setClearedOfSnow(value)}
            options={[
              {
                name: t("common:yes"),
                value: "true",
              },
              {
                name: t("common:no"),
                value: "false",
              },
            ]}
          />

          {clearedOfSnow === "false" && (
            <div className="mt-3">
              <AlertSimple title={t("park:parkSnowNotClearedTitle")} />
            </div>
          )}
        </div>
      </div>

      <Spacer h={10} />
    </ParkAddShell>
  );
}
