import keys from "@/config/keys";
import { CPError, CPErrorResponse } from "@/models/errors/CPError";
import { CPException } from "@/models/exceptions/CPException";
import { CPClient } from "@clicknpark/sdk";
import axios, { AxiosError } from "axios";
import Cookies from "js-cookie";

const api = axios.create({
  baseURL: import.meta.env.VITE_CLICKNPARK_API_URL,
});

api.interceptors.response.use(
  (response) => response,
  (error) => {
    const axiosError = error as AxiosError<CPErrorResponse>;

    let cpError: CPError = axiosError.response?.data.error || {
      code: "DEFAULT_ERROR",
    };

    if (axiosError.code === "ECONNABORTED") {
      cpError.code = "TIMEOUT_ERROR";
      cpError.message = "TIMEOUT_ERROR";
    }

    if (!axiosError.response?.status) {
      cpError.code = "NETWORK_ERROR";
      cpError.message = "NETWORK_ERROR";
    }

    return Promise.reject(new CPException(cpError));
  }
);

export const clicknpark = new CPClient(import.meta.env.VITE_CLICKNPARK_API_URL);

export const setHttpAuthToken = (token: string) => {
  api.defaults.headers.common["x-auth-token"] = token;
  clicknpark.setAuthToken(token);
};

export const resetHttpAuthToken = () => {
  delete api.defaults.headers.common["x-auth-token"];
  clicknpark.removeAuthToken();
};

const savedAuthToken = Cookies.get(keys.authToken);

if (savedAuthToken) {
  setHttpAuthToken(savedAuthToken);
}

export default api;
