import Loader from "@/components/layout/Loader";
import keys from "@/config/keys";
import { CPException } from "@/models/exceptions/CPException";
import { useValidateAuthTokenQuery } from "@/services/auth";
import { useGetUserQuery } from "@/services/user";
import {
  clearStorage,
  removeAuthToken,
  removeBifrostEmail,
  removeBifrostSkipOnboarding,
} from "@/utils/storage";
import { useQueryClient } from "@tanstack/react-query";
import mixpanel from "mixpanel-browser";
import { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { useLocation, useNavigate } from "react-router-dom";

/*

Protected routes are routes that require the user to be logged in to access.
We also verify the URL and redirect to the new URL if it's an old URL.

*/

export default function GateAuth({ children }: { children: JSX.Element }) {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const location = useLocation();
  const [cookie] = useCookies([keys.authToken]);
  const authToken = cookie[keys.authToken];

  const [displaySpinner, setDisplaySpinner] = useState(true);

  /* 
  If the user is not logged in or if the auth token is invalid, redirect to auth page */

  function redirectToAuth() {
    window.location.href = `${
      import.meta.env.VITE_CLICKNPARK_AUTH_APP_URL
    }/?redirect=${
      window.location.origin + window.location.pathname
    }&referrer=dashboard`;
    // navigate("/auth", { replace: true, state: { from: location } });
  }

  function redirectToSignupProfile() {
    navigate("/auth/signup/profile", {
      replace: true,
      state: { from: location },
    });
  }

  function forceLogoutAndRedirectToAuth() {
    clearStorage();
    removeAuthToken();
    removeBifrostEmail();
    removeBifrostSkipOnboarding();
    queryClient.clear();
    mixpanel?.reset();
    redirectToAuth();
  }

  const { error, isLoading: isValidatingToken } = useValidateAuthTokenQuery(
    !!authToken
  );

  const {
    data: user,
    error: getUserError,
    isLoading: isLoadingUser,
  } = useGetUserQuery(!!authToken);

  useEffect(() => {
    if (!authToken) {
      redirectToAuth();
      return;
    }

    /* 1. Validate token */

    // Run the operations only when the token is validated and the user is loaded
    if (isValidatingToken || isLoadingUser) return;

    const invalidToken =
      error instanceof CPException && error.code?.toString() === "106";

    if (invalidToken) {
      forceLogoutAndRedirectToAuth();
      return;
    }

    /* 2. Make sure that all user info is filled out */

    if (getUserError) {
      forceLogoutAndRedirectToAuth();
      return;
    }

    if (!user?.firstName || !user?.lastName) {
      redirectToSignupProfile();
      return;
    }

    /* 3. Unlock the gate */

    setDisplaySpinner(false);
  }, [
    isValidatingToken,
    isLoadingUser,
    authToken,
    user,
    error,
    getUserError,
    location.pathname,
  ]);

  if (displaySpinner) {
    return <Loader backgroundStyle="opaque" />;
  }

  return children;
}
