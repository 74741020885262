import { Place } from "@/@types/park";
import Loader from "@/components/layout/Loader";
import TopBanner from "@/components/TopBanner";
import { Button } from "@/components/ui/button";
import keys from "@/config/keys";
import HelpPanel from "@/containers/HelpPanel";
import OnboardingMenu, {
  OnboardingMenuItem,
} from "@/containers/OnboardingMenu";
import useExitAnimation from "@/hooks/animation/useExitAnimation";
import useOnLogout from "@/hooks/use-on-logout.hook";
import useOnboarding from "@/hooks/useOnboarding";
import {
  getAltLanguageLabel,
  getLanguage,
  switchLanguage,
} from "@/utils/language";
import {
  getBifrostEmail,
  saveParkPlace,
  setAuthPreflightEmail,
} from "@/utils/storage";
import * as tracker from "@/utils/tracker";
import { IconChevronDown } from "@tabler/icons-react";
import i18next from "i18next";
import { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { useTranslation } from "react-i18next";
import { useNavigate, useSearchParams } from "react-router-dom";
import { getGeocode, getLatLng } from "use-places-autocomplete";

export default function OnboardingStart() {
  const logout = useOnLogout();
  const { t } = useTranslation(["onboarding", "common"]);
  const [helpPanelOpen, setHelpPanelOpen] = useState(false);
  const [submenuOpen, setSubmenuOpen] = useState(false);
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const [cookie] = useCookies([keys.authToken]);
  const authToken = cookie[keys.authToken];

  const { launchExitAnimation, animatingExit, exitAnimationClassNames } =
    useExitAnimation({});

  const { park, parkSubmittedButNotApproved } = useOnboarding();

  // We got the coordinates from the website estimator
  const [loading, setLoading] = useState(false);
  const [skipStepOne, setSkipStepOne] = useState(false);

  useEffect(() => {
    if (!authToken) return;
    if (parkSubmittedButNotApproved) return;
    if (park) navigate("/onboarding");
  }, [park, parkSubmittedButNotApproved, authToken]);

  useEffect(() => {
    if (searchParams.get("language")) {
      const language = searchParams.get("language");
      if (language === "fr" || language === "en") {
        i18next.changeLanguage(language);
      }
    }

    if (searchParams.get("email")) {
      const email = searchParams.get("email");
      if (email) setAuthPreflightEmail(email);
    }

    if (searchParams.get("address")) {
      setLoading(true);

      (async () => {
        const results = await getGeocode({
          address: searchParams.get("address"),
        });

        const { lat, lng } = await getLatLng(results[0]);

        type ClicknparkAddressComponents =
          | "streetNumber"
          | "street"
          | "city"
          | "state"
          | "country"
          | "zip";

        const address: Place = {
          streetNumber: "",
          street: "",
          city: "",
          state: "",
          country: "",
          zip: "",
          coordinates: { latitude: lat, longitude: lng },
        };

        const typesToGet: {
          google: string;
          clicnpark: ClicknparkAddressComponents;
        }[] = [
          { google: "street_number", clicnpark: "streetNumber" },
          { google: "route", clicnpark: "street" },
          { google: "locality", clicnpark: "city" },
          { google: "administrative_area_level_1", clicnpark: "state" },
          { google: "country", clicnpark: "country" },
          { google: "postal_code", clicnpark: "zip" },
        ];

        typesToGet.forEach((typeToGet) => {
          results[0].address_components.forEach((component) => {
            if (component["types"].includes(typeToGet.google)) {
              address[typeToGet.clicnpark] = component.long_name;
            }
          });
        });

        saveParkPlace(address);
        setSkipStepOne(true);
        setLoading(false);
      })();
    }
  }, [searchParams]);

  const menuItems: OnboardingMenuItem[] = [
    {
      name: t("common:switchToLanguage", {
        language: getAltLanguageLabel(false),
      }),
      onClick: () => {
        switchLanguage();
        setSubmenuOpen(false);
      },
    },
  ];

  if (authToken) {
    menuItems.push({
      name: t("common:logout"),
      onClick: logout,
    });
  } else {
    menuItems.push({
      name: t("common:login"),
      onClick: () => {
        navigate("/auth");
      },
    });
  }

  const headerClassName = "h-[85px] md:h-[92px] px-5";
  const footerClassName = "h-[85px] md:h-[94px] px-5";
  const mainClassName = getBifrostEmail()
    ? "h-[calc(100dvh-85px-85px-44px)] md:h-[calc(100dvh-92px-94px-44px)]"
    : "h-[calc(100dvh-85px-85px)] md:h-[calc(100dvh-92px-94px)]";

  return (
    <>
      {getBifrostEmail() && (
        <TopBanner
          title={t("common:loggedInAs", {
            email: getBifrostEmail(),
          })}
        />
      )}

      <header
        className={`${headerClassName} flex items-center justify-between border-b border-b-silver-800 bg-white`}
      >
        <img
          className="h-[32px] w-[175px]"
          src={`/images/layout/logo-${getLanguage()}-CA.svg`}
          alt="clicknpark"
        />

        <div className="flex items-center justify-between">
          <Button
            className="mr-3"
            variant="outline"
            onClick={() => setHelpPanelOpen(true)}
          >
            <div className="flex items-center">{t("common:help")}</div>
          </Button>

          <div className="relative">
            <Button
              variant="outline"
              onClick={() => setSubmenuOpen(!submenuOpen)}
            >
              <div className="flex items-center">
                {t("common:menu")}
                <IconChevronDown
                  className={`ml-1 h-[20px] w-[20px] ${
                    submenuOpen ? "rotate-180" : ""
                  } transition-all`}
                />
              </div>
            </Button>

            {submenuOpen && (
              <div className="absolute right-0 top-[50px] z-30">
                <OnboardingMenu items={menuItems} />
              </div>
            )}
          </div>
        </div>
      </header>

      <main
        className={`${mainClassName} relative mx-auto flex max-w-[1800px] flex-1 items-center overflow-hidden bg-white md:px-0 ${
          animatingExit && exitAnimationClassNames
        }`}
      >
        <div className="h-full overflow-auto px-8 py-8">
          <div className="h-full flex-1 flex-wrap md:flex md:content-center md:items-center md:justify-center">
            <div className="w-full animate-fade pr-0 animate-delay-200 animate-alternate animate-duration-[1000ms] animate-once animate-ease-in-out lg:w-6/12 lg:pr-10">
              <h1 className="lg:text-7xl 2xl:text-8xl bg-gradient-to-br from-rapide-600 to-sky-200 bg-clip-text text-4xl font-bold text-transparent sm:text-5xl">
                {t("onboarding:generateRevenuesTitle")}
              </h1>

              <p className="mt-6 max-w-3xl text-xl font-semibold">
                {t("onboarding:generateRevenuesDescription")}
              </p>
            </div>

            <div className="w-full pl-0 lg:w-6/12 lg:pl-10">
              <ul
                role="list"
                className="divide-y divide-gray-200 pl-0 xl:pl-16"
              >
                <li className="animate-fade py-6 animate-delay-[1000ms] animate-alternate animate-duration-[750ms] animate-once animate-ease-in-out md:py-8 lg:py-12">
                  <img
                    src="/images/onboarding/icon-park.svg"
                    className="mb-3"
                  />
                  <h2 className="mb-3 text-lg font-bold">
                    {t("onboarding:addParkingTitle")}
                  </h2>
                  <p className="text-md">
                    {t("onboarding:addParkingDescription")}
                  </p>
                </li>
                <li className="animate-fade py-6 animate-delay-[1500ms] animate-alternate animate-duration-[750ms] animate-once animate-ease-in-out md:py-8 lg:py-12">
                  <img
                    src="/images/onboarding/icon-verification.svg"
                    className="mb-3"
                  />
                  <h2 className="mb-3 text-lg font-bold">
                    {t("onboarding:completeKycTitle")}
                  </h2>
                  <p className="text-md">
                    {t("onboarding:completeKycDescription")}
                  </p>
                </li>
                <li className="animate-fade py-6 animate-delay-[2000ms] animate-alternate animate-duration-[750ms] animate-once animate-ease-in-out md:py-8 lg:py-12">
                  <img
                    src="/images/onboarding/icon-link-bank-account.svg"
                    className="mb-3"
                  />
                  <h2 className="mb-3 text-lg font-bold">
                    {t("onboarding:configurePaymentsTitle")}
                  </h2>
                  <p className="text-md">
                    {t("onboarding:configurePaymentsDescription")}
                  </p>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </main>

      <footer
        className={`${footerClassName} relative z-20 mx-auto flex w-full items-center justify-center border-t border-silver-800 bg-white pb-[5px] sm:static md:px-0`}
      >
        <Button
          size="lg"
          onClick={async () => {
            await launchExitAnimation();
            tracker.track.onboarding.started();
            if (skipStepOne) {
              navigate("/parks/add/2");
            } else {
              navigate("/parks/add");
            }
          }}
        >
          {t("onboarding:startNow")}
        </Button>
      </footer>

      <HelpPanel open={helpPanelOpen} onClose={() => setHelpPanelOpen(false)} />

      {authToken && loading && <Loader />}
    </>
  );
}
