import { FCP_User } from "@/@types/user";
import * as Sentry from "@sentry/react";
import i18next from "i18next";
import { useEffect } from "react";
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from "react-router-dom";

function isEnabled() {
  switch (import.meta.env.VITE_ENV) {
    case "development":
    case "staging":
    case "production":
      return true;
    default:
      return false;
  }
}

export function configureSentry() {
  if (!isEnabled()) return;

  Sentry.init({
    dsn: import.meta.env.VITE_SENTRY_DSN,
    integrations: [
      Sentry.reactRouterV6BrowserTracingIntegration({
        useEffect: useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      }),
      Sentry.replayIntegration(),
    ],
    enabled: true,
    environment: import.meta.env.VITE_ENV,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
    tracesSampleRate: 0.5,
  });
}

export function setLoggerUserContext(user: Partial<FCP_User>) {
  if (!isEnabled()) return;

  user = user || null;
  const scope = Sentry.getCurrentScope();

  scope.setUser({
    "User ID": user?.objectId || "N/A",
    "User email": user?.email || "N/A",
    "User preferred locale": user?.locale || "N/A",
    "Navigation language": i18next.language,
    "User fullname":
      user?.firstName && user?.lastName
        ? user.firstName + " " + user.lastName
        : "N/A",
  });
}
