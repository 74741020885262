import Loader from "@/components/layout/Loader";
import TopBanner from "@/components/TopBanner";
import { Button } from "@/components/ui/button";
import HelpPanel from "@/containers/HelpPanel";
import OnboardingMenu, {
  OnboardingMenuItem,
} from "@/containers/OnboardingMenu";
import useEntryAnimation from "@/hooks/animation/useEntryAnimation";
import useOnLogout from "@/hooks/use-on-logout.hook";
import useOnboarding from "@/hooks/useOnboarding";
import { useGenerateKycLinkQuery } from "@/services/kyc";
import { useUpdateOnboardingStepMutation } from "@/services/owner";
import {
  getAltLanguageLabel,
  getLanguage,
  switchLanguage,
} from "@/utils/language";
import { getBifrostEmail, setBifrostSkipOnboarding } from "@/utils/storage";
import * as tracker from "@/utils/tracker";
import { IconBulb, IconChevronDown, IconShieldLock } from "@tabler/icons-react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useEffectOnce } from "react-use";

export default function KycStart() {
  const navigate = useNavigate();
  const logout = useOnLogout();

  const [helpPanelOpen, setHelpPanelOpen] = useState(false);
  const [submenuOpen, setSubmenuOpen] = useState(false);
  const { t } = useTranslation(["onboarding", "common"]);
  const { loading, kycRequired } = useOnboarding();
  const { data: kycLink, isFetching } = useGenerateKycLinkQuery({
    refreshUrl: window.location.origin + "/onboarding/kyc/start",
    returnUrl: window.location.origin + "/onboarding/kyc/end",
    enabled: !loading,
  });

  const { launchEntryAnimation, animatingEntry, entryAnimationClassNames } =
    useEntryAnimation({
      entryAnimationDelay: 0,
    });

  useEffect(() => {
    if (!loading) {
      launchEntryAnimation();
    }
  }, [loading, isFetching, kycRequired]);

  const { mutateAsync: updateOnboadingStep } =
    useUpdateOnboardingStepMutation();

  useEffectOnce(() => {
    tracker.track.onboarding.landedOnOnKyc();
    updateOnboadingStep("kyc-started");
  });

  const menuItems: OnboardingMenuItem[] = [
    {
      name: t("common:switchToLanguage", {
        language: getAltLanguageLabel(false),
      }),
      onClick: () => {
        switchLanguage();
        setSubmenuOpen(false);
      },
    },
    {
      name: t("common:logout"),
      onClick: logout,
    },
  ];

  if (getBifrostEmail()) {
    menuItems.push({
      name: t("common:bifrostLeave"),
      onClick: () => {
        setBifrostSkipOnboarding();
        navigate("/");
      },
    });
  }

  const headerClassName = "h-[85px] md:h-[92px] px-5";
  const footerClassName = "h-[85px] md:h-[94px] px-5";
  const mainClassName = getBifrostEmail()
    ? "h-[calc(100dvh-85px-85px-44px)] md:h-[calc(100dvh-92px-94px-44px)]"
    : "h-[calc(100dvh-85px-85px)] md:h-[calc(100dvh-92px-94px)]";

  return (
    <>
      {getBifrostEmail() && (
        <TopBanner
          title={t("common:loggedInAs", {
            email: getBifrostEmail(),
          })}
        />
      )}

      <header
        className={`${headerClassName} flex items-center justify-between border-b border-b-silver-800 bg-white`}
      >
        <img
          className="h-[32px] w-[175px]"
          src={`/images/layout/logo-${getLanguage()}-CA.svg`}
          alt="clicknpark"
        />

        <div className="flex items-center justify-between">
          <Button
            className="mr-3"
            variant="outline"
            onClick={() => setHelpPanelOpen(true)}
          >
            <div className="flex items-center">{t("common:help")}</div>
          </Button>

          <div className="relative">
            <Button
              variant="outline"
              onClick={() => setSubmenuOpen(!submenuOpen)}
            >
              <div className="flex items-center">
                {t("common:menu")}
                <IconChevronDown
                  className={`ml-1 h-[20px] w-[20px] ${
                    submenuOpen ? "rotate-180" : ""
                  } transition-all`}
                />
              </div>
            </Button>

            {submenuOpen && (
              <div className="absolute right-0 top-[50px] z-30">
                <OnboardingMenu items={menuItems} />
              </div>
            )}
          </div>
        </div>
      </header>

      <main
        className={`${mainClassName} relative mx-auto flex max-w-[1800px] flex-1 items-center overflow-hidden bg-white opacity-0 md:px-0 ${
          animatingEntry && entryAnimationClassNames
        }`}
      >
        <div className="h-full overflow-auto px-8 py-8">
          <div className="h-full flex-1 flex-wrap content-center items-center justify-center lg:flex">
            <div className="w-full animate-fade pr-0 animate-delay-200 animate-alternate animate-duration-[1000ms] animate-once animate-ease-in-out lg:w-6/12 lg:pr-10">
              <h1 className="lg:text-7xl 2xl:text-8xl bg-gradient-to-br from-rapide-600 to-sky-200 bg-clip-text text-4xl font-bold text-transparent sm:text-5xl">
                {t("onboarding:kycTitle")}
              </h1>

              <p className="mt-6 max-w-3xl text-xl font-semibold">
                {t("onboarding:kycSubtitle")}
              </p>

              <ul className="mt-5 font-bold text-rapide-600">
                <li className="mt-5 flex">
                  <IconBulb className="mt-[-1px] w-[22px]" />
                  <div className="ml-3">
                    {t("onboarding:kycChangeRepresentativeAtAllTime")}
                  </div>
                </li>
                <li className="mt-4 flex">
                  <IconShieldLock className="w-[20px]" />
                  <div className="ml-3">
                    {t("onboarding:kycDataIsEncryptedAndSecured")}
                    <div className="mt-2 font-normal">
                      <a
                        className="underline hover:no-underline"
                        href="https://stripe.com/docs/security/stripe"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {t("onboarding:kycDataIsEncryptedAndSecuredLearnMore")}
                      </a>
                    </div>
                  </div>
                </li>
              </ul>

              <p className="mt-6 max-w-3xl text-xl font-semibold">
                {t("onboarding:generateRevenuesDescription")}
              </p>
            </div>

            <div className="w-full pl-0 lg:w-6/12 lg:pl-10">
              <p className="text-xl">
                <strong>{t("onboarding:kycWhyTitle")}</strong>
              </p>
              <p className="mt-4">{t("onboarding:kycWhyDescription1")}</p>
              <p className="mt-8 text-xl">
                <strong>{t("onboarding:kycWhyImportant")}</strong>
              </p>
              <p className="mt-4">{t("onboarding:kycWhyDescription2")}</p>
              <p className="mt-4">{t("onboarding:kycWhyDescription3")}</p>
            </div>
          </div>
        </div>
      </main>

      <footer
        className={`${footerClassName} relative z-20 mx-auto flex w-full items-center justify-center border-t border-silver-800 bg-white pb-[5px] sm:static md:px-0`}
      >
        <Button
          disabled={!kycLink || isFetching || loading}
          size="lg"
          onClick={() => {
            if (kycLink) {
              window.location.href = kycLink;
              tracker.track.onboarding.startedKyc();
            }
          }}
        >
          {t("onboarding:startNow")}
        </Button>
      </footer>

      <HelpPanel open={helpPanelOpen} onClose={() => setHelpPanelOpen(false)} />

      {(isFetching || loading) && (
        <div className="fixed bottom-0 left-0 right-0 top-0 bg-white/25">
          <Loader />
        </div>
      )}
    </>
  );
}
