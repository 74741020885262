import { useMutation, useQuery } from "@tanstack/react-query";

import { FCP_User, FCP_Vehicle } from "@/@types/user";
import { UserLocale } from "@/containers/forms/FormNotificationsLocale";
import { CPException } from "@/models/exceptions/CPException";
import { setLoggerUserContext } from "@/services/sentry.services";
import api from "./api";

export const useGetUserQuery = (enabled = true) => {
  return useQuery<Partial<FCP_User>, CPException, Partial<FCP_User>>({
    queryKey: ["user"],
    queryFn: async () => {
      const { data } = await api.get<Partial<FCP_User>>(`/v2/users/me`);
      setLoggerUserContext(data);
      return data;
    },
    refetchOnMount: true,
    refetchOnWindowFocus: false,
    enabled,
  });
};

export interface UpdateUserRequest {
  [key: string]: unknown;
}

export const useUpdateUserMutation = () => {
  return useMutation({
    mutationKey: ["updateUser"],
    mutationFn: async (updates: UpdateUserRequest) => {
      const params: { [key: string]: unknown } = { updates };
      const { data } = await api.post<Partial<FCP_User>>(
        `/v2/users/me/update`,
        params
      );
      return data;
    },
  });
};

export const useUpdateUserBillingEmailsMutation = () => {
  return useMutation({
    mutationKey: ["updateUserBillingEmails"],
    mutationFn: async (billingEmails: string[]) => {
      const params: { [key: string]: unknown } = { billingEmails };
      const { data } = await api.put<Partial<FCP_User>>(`/v3/users/me`, params);
      return data;
    },
  });
};

export const useUpdateUserLocale = () => {
  return useMutation({
    mutationKey: ["updateUserLocale"],
    mutationFn: async (locale: UserLocale) => {
      const { data } = await api.put<UserLocale>(`/v2/users/me/locale`, {
        locale,
      });
      return data;
    },
  });
};

export interface UpdateUserPasswordRequest {
  currentPassword: string;
  newPassword: string;
}

export const useUpdateUserPasswordMutation = () => {
  return useMutation({
    mutationKey: ["updatePassword"],
    mutationFn: async ({
      currentPassword,
      newPassword,
    }: UpdateUserPasswordRequest) => {
      const { data } = await api.post<Partial<FCP_User>>(
        `/v2/users/me/update`,
        {
          updates: { password: newPassword },
          oldPassword: currentPassword,
        }
      );
      return data;
    },
  });
};

export const useAcceptTermsOfUseMutation = () => {
  return useMutation({
    mutationKey: ["tos"],
    mutationFn: async () => {
      const { data } = await api.post(`/users/me/acceptTermsOfService/1-0-0`);
      return data;
    },
  });
};

type GetVehiclesQuery = FCP_Vehicle[];

export const useGetVehiclesQuery = () => {
  return useQuery({
    queryKey: ["vehiclesWhitelist"],
    queryFn: async () => {
      const { data } = await api.get<GetVehiclesQuery>(`/vehicles`);
      return data;
    },
    refetchOnMount: true,
    refetchOnWindowFocus: false,
  });
};

export interface DeleteVehicleRequest {
  id: string;
}

export const useDeleteVehicleMutation = () => {
  return useMutation({
    mutationKey: ["updateBankAccount"],
    mutationFn: async ({ id }: DeleteVehicleRequest) => {
      const { data } = await api.delete(`/vehicles/${id}`);
      return data.externalAccount;
    },
  });
};

// async fetchVehicles(): Promise<HttpResult<{ [key: string]: FCP_Vehicle }>> {
//   if (!http) return { problem: "no-client" };

//   const { setVehicles } = useAccountStore.getState();

//   const { ok, problem, data }: ApiResponse<any> = await http.get("/vehicles");

//   if (!ok)
//     return {
//       problem,
//       error: data?.error ? errors.fetchVehicles(data.error) : unhandledError
//     };

//   const vehicles: { [key: string]: FCP_Vehicle } = {};
//   const formatted: FCP_Vehicle[] = data.map((vehicle: unknown) => dto.formatUserVehicle(vehicle));
//   formatted.forEach((m) => (vehicles[m.id] = m));

//   setVehicles(vehicles);

//   return { data: vehicles };
// },

export interface AddVehicleToWhitelistRequest {
  modelId?: string;
  noModelString?: string;
  plate: string;
}

export const useAddVehicleToWhitelistMutation = () => {
  return useMutation({
    mutationKey: ["addVehicleWhitelist"],
    mutationFn: async (params: AddVehicleToWhitelistRequest) => {
      const { data } = await api.post(`/vehicles`, params);
      return data;
    },
  });
};

export type BillingReport = {
  destinationEmails: string[];
  id: string;
  locale: string;
  periodEnd: string;
  periodStart: string;
  reportSubType: string;
  reportType: string;
  targetId: string;
  targetType: string;
};

export type BillingReportReponse = {
  count: number;
  data: BillingReport[];
  type: "list";
};

export const useGetBillingReportsQuery = () => {
  return useQuery<BillingReportReponse, CPException, BillingReportReponse>({
    queryKey: ["billingReports"],
    queryFn: async () => {
      const { data } = await api.get<BillingReportReponse>(
        "/v3/users/me/connect/reports/ownerDeposits",
        {
          params: {
            minDate: "2024-02-01",
          },
        }
      );
      return data;
    },
    refetchOnMount: true,
    refetchOnWindowFocus: false,
  });
};

export const useDownloadBillinReportMutation = () => {
  return useMutation({
    mutationFn: async ({ id, filename }: { id: string; filename: string }) => {
      const { data } = await api.get(`/v3/users/me/connect/reports/pdf/${id}`, {
        responseType: "blob",
      });

      const downloadLink = document.createElement("a");
      downloadLink.href = URL.createObjectURL(data);
      downloadLink.download = filename;

      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
    },
  });
};
