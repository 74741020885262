import { useGetConnectedAccountQuery } from "@/services/connect";
import { useGetParksQuery } from "@/services/park";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

interface Requirements {
  kycRequired?: boolean;
  parkSubmitRequired?: boolean;
  payoutsSetupRequired?: boolean;
  onboardingComplete?: boolean;
}

export default function useOnboarding() {
  const [searchParams] = useSearchParams();

  const { data: parkings } = useGetParksQuery();
  const { data, error } = useGetConnectedAccountQuery();

  const [loading, setLoading] = useState(true);
  const [accountRequirements, setAccountRequirements] = useState<Requirements>(
    {}
  );

  const [park, setPark] = useState<any>();
  const [parkSubmitRequired, setParkSubmitRequired] = useState(false);
  const [parkSubmittedButNotApproved, setParkSubmittedButNotApproved] =
    useState(false);

  function setParkData() {
    if (!parkings) return;

    if (searchParams.get("id")) {
      const park = parkings.find((p) => p.objectId === searchParams.get("id"));

      if (park) {
        setPark(park);
      } else {
        setPark(parkings[0]);
      }
    }

    setParkSubmitRequired(
      parkings.filter((p) => p.status === "submitted").length === 0
    );

    setParkSubmittedButNotApproved(
      parkings.filter(
        (p) => p.status === "submitted" && !p.validationObject?.valid
      ).length > 0
    );
  }

  useEffect(() => {
    if (parkings) {
      // If the connected account has never been set, the error 551 is thrown, we need to verify identity
      if (error?.code.toString() === "551") {
        setAccountRequirements({
          ...accountRequirements,
          kycRequired: true,
          payoutsSetupRequired: true,
        });

        setParkData();

        setLoading(false);
      }
    }
  }, [error, parkings]);

  useEffect(() => {
    if (data && parkings) {
      const { accountData } = data;
      const { requirements, bankAccounts } = accountData;

      // Unless requirements only includes external_account, we need to verify identity
      const stripeRequirements: string[] =
        requirements?.currently_due?.filter(
          (r: string) =>
            r !== "external_account" &&
            r !== "individual.verification.proof_of_liveness" // temporary fix for the liveness check because it makes the user go through the whole process twice
        ) || [];

      setAccountRequirements({
        ...accountRequirements,
        kycRequired: stripeRequirements.length > 0,
        payoutsSetupRequired: !bankAccounts || bankAccounts.length === 0,
      });

      setParkData();

      setLoading(false);
    }
  }, [data, parkings]);

  return {
    loading,
    ...accountRequirements,
    parkSubmitRequired,
    parkSubmittedButNotApproved,
    onboardingComplete: !accountRequirements.kycRequired && !parkSubmitRequired,
    park,
  };
}
