import { FCP_Address, FCP_Location, FCP_Park } from "@/@types/park";
import { useLazyGetPark } from "@/hooks/query/useLazyGetPark";
import { useLazyGetParks } from "@/hooks/query/useLazyGetParks";
import useOnboarding from "@/hooks/useOnboarding";
import { CPException } from "@/models/exceptions/CPException";
import { useUpdateOnboardingStepMutation } from "@/services/owner";
import { useCreateParkMutation, useUpdateParkMutation } from "@/services/park";
import {
  getCurrentPark,
  getParkPlace,
  removeCurrentPark,
  removeParkPlace,
  saveCurrentPark,
} from "@/utils/storage";
import * as tracker from "@/utils/tracker";
import { useQueryClient } from "@tanstack/react-query";
import { PropsWithChildren, createContext, useEffect, useState } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";

export type ParkAddContextProps = {
  step: number;
  loading: boolean;
  canGoNext: boolean;
  canGoBack: boolean;
  onboardingComplete: boolean;
  next: () => void;
  back: () => void;
  goTo: (step: number) => void;
  getPark: (id: string) => void;
  createPark: (address: FCP_Address, location: FCP_Location) => void;
  updatePark: (updates: any, refetchWithPhotos?: boolean) => void;
  exit: () => void;
};

export const TOTAL_NUMBER_OF_STEP = 12;

/*
  We want to map the step name to a number that will be used in the URL
  URL is agnostic to the step name, it only cares about the step number.
*/

export enum ParkAddStep {
  Address = "address",
  AddressConfirmation = "address-confirmation",
  Location = "location",
  Directions = "directions",
  VehicleSize = "vehicle-size",
  Features = "features",
  Photos = "photos",
  Schedule = "schedule",
  ScheduleExceptions = "schedule-exceptions",
  Pricing = "pricing",
  VehiclesWhitelist = "vehicles-whitelist",
  Review = "review",
}

export const stepsMap = {
  [ParkAddStep.Address]: 1,
  [ParkAddStep.AddressConfirmation]: 2,
  [ParkAddStep.Location]: 3,
  [ParkAddStep.Directions]: 4,
  [ParkAddStep.VehicleSize]: 5,
  [ParkAddStep.Features]: 6,
  [ParkAddStep.Photos]: 7,
  [ParkAddStep.Schedule]: 8,
  [ParkAddStep.ScheduleExceptions]: 9,
  [ParkAddStep.Pricing]: 10,
  [ParkAddStep.VehiclesWhitelist]: 11,
  [ParkAddStep.Review]: 12,
} as const;

export const ParkAddContext = createContext<ParkAddContextProps>({
  step: 1,
  loading: true,
  canGoNext: true,
  canGoBack: false,
  onboardingComplete: false,
  next: () => {},
  back: () => {},
  goTo: () => {},
  getPark: () => {},
  createPark: () => {},
  updatePark: () => {},
  exit: () => {},
});

function hardRedirect(path: string) {
  const origin = window.location.origin;
  window.location.href = `${origin}${path.startsWith("/") ? path : `/${path}`}`;
}

function isValidStepName(name: string): name is ParkAddStep {
  return Object.values(ParkAddStep).includes(name as ParkAddStep);
}

export function resumeToStep(stepName: string, query?: string) {
  if (!isValidStepName(stepName)) {
    console.error(`Invalid step name: ${stepName}`);
    hardRedirect("/parks/add/1");
    return;
  }

  const step = stepsMap[stepName];
  hardRedirect(`/parks/add/${step}${query ?? ""}`);
}

export function mapLegacyStepNumberToStepName(step?: number): ParkAddStep {
  if (!step) return ParkAddStep.Address;

  switch (step) {
    case 1:
      return ParkAddStep.Address;
    case 2:
      return ParkAddStep.AddressConfirmation;
    case 3:
      return ParkAddStep.Location;
    case 4:
      return ParkAddStep.Directions;
    case 5:
      return ParkAddStep.VehicleSize;
    case 6:
      return ParkAddStep.Features;
    case 7:
      return ParkAddStep.Photos;
    case 8:
      return ParkAddStep.Schedule;
    case 9:
      return ParkAddStep.ScheduleExceptions;
    case 10:
      return ParkAddStep.Pricing;
    case 11:
      return ParkAddStep.VehiclesWhitelist;
    case 12:
      return ParkAddStep.Review;
  }

  // Handle edge cases from legacy parks
  if (step > 12) {
    console.error(`Invalid step number: ${step}`);
    return ParkAddStep.Review;
  }

  return ParkAddStep.Address;
}

export default function ParkAddProvider({ children }: PropsWithChildren) {
  const navigate = useNavigate();
  const location = useLocation();
  const queryClient = useQueryClient();
  const [searchParams] = useSearchParams();
  const {
    onboardingComplete,
    parkSubmittedButNotApproved,
    loading: onboardingLoading,
  } = useOnboarding();

  const [step, setStep] = useState(1);
  const [loading, setLoading] = useState(true);
  const [canGoNext, setCanGoNext] = useState(step < TOTAL_NUMBER_OF_STEP);
  const [canGoBack, setCanGoBack] = useState(step > 1);

  const fetchPark = useLazyGetPark();
  const fetchParks = useLazyGetParks();
  const { mutateAsync: createParkAsync } = useCreateParkMutation();
  const { mutateAsync: updateParkAsync } = useUpdateParkMutation();
  const { mutateAsync: updateOnboadingStepAsync } =
    useUpdateOnboardingStepMutation();

  useEffect(() => {
    if (onboardingLoading) return;

    (async () => {
      /* Set step based on URL */
      const potentialCurrentStepFromUrl = location.pathname.match(/\d+/);
      let currentStepFromUrl: number | undefined;

      if (
        potentialCurrentStepFromUrl !== null &&
        parseInt(potentialCurrentStepFromUrl[0])
      ) {
        currentStepFromUrl = parseInt(potentialCurrentStepFromUrl[0]);
        setStep(currentStepFromUrl);
      }

      /* Get/Set current park based on URL query */
      const id = searchParams.get("id");

      let currentPark: Partial<FCP_Park> | undefined;

      // If there is no id and no current step in the URL, force user to reset the flow
      if (
        !id &&
        !currentStepFromUrl &&
        location.pathname !== "/parks/add/submitted"
      ) {
        hardRedirect("/parks/add/1");
      }

      let parkIdQuery = "";

      if (id) {
        try {
          currentPark = await getPark(id);

          parkIdQuery = currentPark?.objectId
            ? `?id=${currentPark.objectId}`
            : "";

          // If there is an id and no current step in the URL, redirect user to his current step
          if (
            id &&
            !currentStepFromUrl &&
            location.pathname !== "/parks/add/submitted"
          ) {
            const stepToResumeTo = currentPark?.uxMetadata?.resumeToStep;

            // Handle legacy resumeToStep which was a number until 2023/09/01
            if (typeof stepToResumeTo === "number") {
              resumeToStep(
                mapLegacyStepNumberToStepName(stepToResumeTo),
                parkIdQuery
              );
            } else if (typeof stepToResumeTo === "string") {
              resumeToStep(stepToResumeTo, parkIdQuery);
            }
          }

          // If the park is a draft, save it to local storage
          if (
            !currentPark?.status ||
            currentPark.status === "draft" ||
            parkSubmittedButNotApproved
          ) {
            saveCurrentPark(currentPark);
          } else if (
            currentPark.status === "submitted" &&
            !parkSubmittedButNotApproved
          ) {
            // If the park is not a draft OR if the park is submitted but not approved, force user to reset the flow
            resetFlow();
            hardRedirect(onboardingComplete ? "/parks" : "/");
            setLoading(false);
            return;
          } else if (!currentPark) {
            resetFlow();
            hardRedirect(`/parks/add/1${parkIdQuery}`);
            setLoading(false);
            return;
          }
        } catch (error) {
          // If the park is not found, force user to reset the flow
          resetFlow();
          hardRedirect("/parks/add/1");
          const exception = error as CPException;
          console.error(exception);
          setLoading(false);
          return;
        }
      } else {
        // If there is no park id in the URL, force user to reset the flow unless they're already at the first step
        if (
          !currentStepFromUrl ||
          (currentStepFromUrl > 1 && !getParkPlace())
        ) {
          resetFlow();
          hardRedirect("/parks/add/1");
          setLoading(false);
          return;
        }
      }

      setLoading(false);
    })();
  }, [onboardingLoading]);

  useEffect(() => {
    if (loading) return;
    setCanGoNext(step < TOTAL_NUMBER_OF_STEP);
    setCanGoBack(step > 1);
  }, [step, loading]);

  function resetFlow() {
    removeCurrentPark();
    removeParkPlace();
  }

  async function getPark(id: string): Promise<Partial<FCP_Park>> {
    return await fetchPark({
      parkId: id,
      options: { fetchPhotos: true, timeTableFormat: true },
    });
  }

  async function createPark(address: FCP_Address, location: FCP_Location) {
    const parks = await fetchParks();
    if (parks.length === 0) updateOnboadingStepAsync("started");

    const data = await createParkAsync({
      address,
      location,
    });

    saveCurrentPark({ objectId: data.id, address, location, status: "draft" });

    queryClient.invalidateQueries({ queryKey: ["parks"] });
  }

  async function updatePark(
    updates: { [key: string]: any },
    refetchWithPhotos = false
  ) {
    const park = getCurrentPark();
    if (!park?.objectId) return;

    let data = await updateParkAsync({
      parkId: park.objectId,
      updates,
    });

    if (refetchWithPhotos) {
      data = await fetchPark({
        parkId: park.objectId,
        options: { fetchPhotos: true, timeTableFormat: true },
      });
    }

    saveCurrentPark({ ...park, ...data });
  }

  async function saveProgression() {
    const park = getCurrentPark();
    await updatePark({
      uxMetadata: {
        ...(park?.uxMetadata ? park.uxMetadata : {}),
        resumeToStep: mapLegacyStepNumberToStepName(step + 1),
      },
    });
  }

  function goTo({ step }: { step: number }) {
    const park = getCurrentPark();
    const parkIdQuery = park?.objectId ? `?id=${park.objectId}` : "";

    /* Navigate to step */
    saveProgression();
    setStep(step);
    navigate(`/parks/add/${step}${parkIdQuery}`);
    setCanGoNext(step < TOTAL_NUMBER_OF_STEP);
    setCanGoBack(step > 1);
  }

  function next() {
    const park = getCurrentPark();
    const parkIdQuery = park?.objectId ? `?id=${park.objectId}` : "";

    /* Track parking creation */

    switch (step) {
      case stepsMap[ParkAddStep.Address]:
        tracker.track.parkAdd.enteredAddress();
        break;
      case stepsMap[ParkAddStep.AddressConfirmation]:
        if (!park?.objectId) return;
        tracker.track.parkAdd.confirmedAddress(park.objectId);
        break;
      case stepsMap[ParkAddStep.Location]:
        if (!park?.objectId) return;
        tracker.track.parkAdd.enteredLocation(park.objectId);
        break;
      case stepsMap[ParkAddStep.Directions]:
        if (!park?.objectId) return;
        tracker.track.parkAdd.enteredDescription(park.objectId);
        break;
      case stepsMap[ParkAddStep.VehicleSize]:
        if (!park?.objectId) return;
        tracker.track.parkAdd.selectedVehicleSize(park.objectId);
        break;
      case stepsMap[ParkAddStep.Features]:
        if (!park?.objectId) return;
        tracker.track.parkAdd.selectedFeatures(
          park.objectId,
          park?.features?.join(", ") || "N/A"
        );
        break;
      case stepsMap[ParkAddStep.Photos]:
        if (!park?.objectId) return;
        tracker.track.parkAdd.uploadedPhotos(
          park.objectId,
          park?.photos?.length.toString() || "N/A"
        );
        break;
      case stepsMap[ParkAddStep.Schedule]:
        if (!park?.objectId) return;
        tracker.track.parkAdd.enteredAvailabilities(
          park.objectId,
          park?.uxMetadata?.scheduleType?.value || "N/A"
        );
        break;
      case stepsMap[ParkAddStep.ScheduleExceptions]:
        if (!park?.objectId) return;
        tracker.track.parkAdd.enteredExceptions(park.objectId);
        break;
      case stepsMap[ParkAddStep.Pricing]:
        if (!park?.objectId) return;
        tracker.track.parkAdd.enteredPricing(
          park.objectId,
          park?.uxMetadata?.pricingMode || "N/A"
        );
        break;
      case stepsMap[ParkAddStep.VehiclesWhitelist]:
        if (!park?.objectId) return;
        tracker.track.parkAdd.setVehiclesWhitelist();
        break;
      case stepsMap[ParkAddStep.Review]:
        if (!park?.objectId) return;
        tracker.track.parkAdd.reviewed(park.objectId);
        break;
    }

    /* Navigate to next step */
    saveProgression();
    setStep(step + 1);
    navigate(`/parks/add/${step + 1}${parkIdQuery}`);
    setCanGoNext(step < TOTAL_NUMBER_OF_STEP);
  }

  function back() {
    const park = getCurrentPark();
    const parkIdQuery = park?.objectId ? `?id=${park.objectId}` : "";

    /* Navigate to back step */
    saveProgression();
    setStep(step - 1);
    navigate(`/parks/add/${step - 1}${parkIdQuery}`);
    setCanGoBack(step > 1);
  }

  function exit() {
    /* Exit onboarding flow */
    saveProgression();
    const park = getCurrentPark();

    if (park?.objectId) {
      tracker.track.parkAdd.exited(park.objectId);
    } else {
      tracker.track.parkAdd.exited("no-park-id");
    }

    setTimeout(() => {
      // Let some time for the progression save to happen
      removeCurrentPark();
      removeParkPlace();
    }, 1000);

    navigate(onboardingComplete ? "/parks" : "/");
  }

  return (
    <ParkAddContext.Provider
      value={{
        step,
        loading,
        canGoNext,
        canGoBack,
        onboardingComplete,
        next: () => next(),
        back: () => back(),
        goTo: (step: number) => goTo({ step }),
        getPark: (id: string) => getPark(id),
        createPark: (address: FCP_Address, location: FCP_Location) =>
          createPark(address, location),
        updatePark: (updates: any, refetchWithPhotos?: boolean) =>
          updatePark(updates, refetchWithPhotos),
        exit: () => exit(),
      }}
    >
      {children}
    </ParkAddContext.Provider>
  );
}
